<template>
  <CModal
    :show.sync="isShowPopup"
    :close-on-backdrop="false"
    :color="modalColor"
    size="lg"
  >
    <template #header>
      <CButtonClose @click="modalCallBack(false)" class="text-black" />
    </template>
    <template #footer-wrapper>
      <div class="border-top d-flex py-3 mx-3">
        <div class="flex-grow-1 d-flex"></div>
        <div class="d-flex">
          <CButton
            type="button"
            color="modal-primary"
            class="px-4"
            @click="closeModal()"
            >{{ buttonLabel }}</CButton
          >
        </div>
      </div>
    </template>
    <div>
      <h2 class="text-center">Welcome to TalentFind World</h2>
      <h4 class="text-center">Hello {{ item.full_name }}</h4>
      <p class="text-center">
        TalentFind World is a single place for you to manage your healthcare
        career.
      </p>
      <p class="text-center">Your personalized portal enables you to:</p>
      <ul class="ml-5">
        <li>Manage and update your profile and documents.</li>
        <li>
          See notifications, updates, and feedback from recruitment teams.
        </li>
        <li>View and apply for our latest opportunities.</li>
        <li>View pre-screened opportunities that match your interests.</li>
      </ul>
      <h4 class="text-center mt-4">Thank you!</h4>
    </div>
  </CModal>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: {},
    },
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    aspectRatio: {
      type: Number,
      default: 0,
    },
    modalColor: {
      type: String,
      default: "modal-primary",
    },
    buttonLabel: {
      type: String,
      default: "Apply",
    },
  },

  methods: {
    closeModal() {
      this.$emit("modalCallBack", false);
    },
    modalCallBack(action) {
      this.$emit("modalCallBack", action);
    },
  },
};
</script>
