<template>
  <div>
    <CModal
      :show.sync="isShowModal"
      :close-on-backdrop="false"
      :color="modalColor"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">{{ modalTitle }}</h6>
        <CButtonClose
          @click="notificationModalCallBack(false)"
          class="text-black"
        />
      </template>
      <template #footer>
        <CButton
          class="ml-2"
          color="primary"
          @click="notificationModalCallBack(false)"
          >OK</CButton
        >
      </template>
      <div>
        <p>
          Our <b>FREE English</b> language assessment will allow you to
          understand your current ability and if needed, we can recommend
          suitable English language training to assist you to obtain a necessary
          level for a particular region/country.
        </p>
        <p>
          You can log your assessment or final results and allow them to be
          viewed by healthcare recruiters. If the recruiters select you for
          interview, they will already know the status of your English language
          level and may offer you funding to support your language studies.
        </p>
        <p>Please select from the following options:</p>
        <CRow>
          <CCol md="6">
            <RadioButton
              name="take_test"
              :value="assessment_test.take_test"
              :options="(options && options['assessmentTest']) || []"
              :vertically="true"
              @change="handleChangeRadioCustom"
            />
          </CCol>
          <CCol
            md="6"
            class="text-center"
          >
            <CButton v-if="
              assessment_test.take_test && assessment_test.take_test.code === 1
            "
            color="primary" @click="navigateToAssessment()">Free Language Assessment Test</CButton>
          </CCol>
        </CRow>
      </div>
    </CModal>
  </div>
</template>
<script>
import RadioButton from "@/components/reusable/Fields/RadioButton";
import { mapActions, mapGetters } from 'vuex'
import Vue from "vue";

export default {
  name: "AssessmentModal",
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    modalColor: {
      type: String,
      default: "primary",
    },
    modalTitle: {
      type: String,
      default: "NOTIFICATION",
    },
  },
  components: {
    RadioButton,
  },
  data() {
    return {
      assessment_test: {},
    };
  },
  computed: {
    ...mapGetters(["getAssessmentTestId"]),
    options() {
      return {
        assessmentTest: [
          { code: 1, label: "Take the FREE test now" },
          { code: 2, label: "Take the FREE test later" },
          { code: 3, label: "I don’t want to take the test" },
        ],
      };
    },
  },
  methods: {
    ...mapActions(["createTestAssessmentId"]),
    notificationModalCallBack(action) {
      this.$emit("modalCallBack", action);
    },
    handleChangeRadioCustom(name, value) {
      Vue.set(this.assessment_test, name, value);
    },
    async navigateToAssessment() {
      await this.createTestAssessmentId()
      if(!this.getAssessmentTestId.completed) {
        this.$router.push(`/assessment/${this.getAssessmentTestId?.assessment_test_id}`);
      } else {
        this.$emit("closeModal", false)
      }
    },
  },
};
</script>
